/* You can add global styles to this file, and also import other style files */

// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
$mainfont : 'Nunito', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
$mulish : 'Mulish', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@font-face {
  font-family: "Butler";
  src: url("./assets/fonts/Butler.woff2") format("woff2"),
  url("./assets/fonts/Butler.woff") format("woff");
  //  url("./assets/fonts/Butler-Bold.woff2")
}

$fontmontserrat: 'Montserrat', sans-serif;
$fontbutler: 'Butler', sans-serif;
$fontLato: 'Lato', sans-serif;

$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
// $themecolor1: #e9eaee; // header
$themeaccentcolor1: #814495;
// $themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu

$menudividerlight: #f1f2f6;
$menudividerdark: #ced6e0;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #57606f;
$titlecolor2: #227093;

// dark theme
// $themecolor1: #aaa69d;
// $themecolor2: #f7f1e3;

// Theme Colors
$themecolor1: #F7D54D;
$themecolor2: #212A42;


// Global styles
html {height: 100%;}
body {margin: 0px !important; font-family: $mainfont, sans-serif;}
h1 { font-size: 26px; font-weight: 700; color: $titlecolor1; }

// Miscellaneous
.toolbarspacer {flex: 1 1 auto;}
.clear {clear: both;}
.actions { & { display: flex; justify-content: center; align-items: center; }
  .MuiSvgIcon-root { color: #454545; cursor: pointer; margin: 0 5px; }
}
.url { color: #343333; }

* [contentEditable="true"]:focus { outline: 0px ; }

// Button Styles
.redbtn { background: $buttoncolor1;color: #fff; }
.redbtn:hover { color: #fff;background: $buttonaccentcolor1; }
.greybtn { background: $buttoncolor2;color: $titlecolor1; }
.greybtn:hover { color: $titlecolor1; }
.black-btn { background-color: #122845 !important; color: #FFFFFF !important; font-weight: 600 !important; font-family: $fontmontserrat !important; }
.filterbtn { background: transparent !important;color: $titlecolor1; }
.green-btn { font-family: $fontmontserrat !important; background-color: #00a5b8; color: $themecolor2; font-weight: 600; padding: 5px 10px; }
.MuiButton-root.gray-btn { & { font-family: $fontmontserrat; background-color: #e0e0e0; color: #343333; margin: 15px auto; padding: 5px 10px; font-weight: 600; }
  &:hover { background-color: #cecece; }
}
.MuiButton-root.salesforce-btn { background-color: #0D9DDA; color: #FFF; font-weight: 600; font-family: $fontmontserrat; margin: 0 0 0 auto; padding: 7px 14px; text-transform: capitalize; font-size: 16px; align-self: flex-start; }
.MuiButton-root.salesforce-btn:hover { background-color: #3baed3; color: #FFF; }
.duration .mce-content-body p { margin: 0 auto; }

// Remove browser unwanted styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $themecolor2 inset !important;
}

.MuiChip-label { font-family: $mainfont; text-transform: uppercase; font-size: 10px; font-weight: 700; }


.vertical-layout, div.defaultLightbox, div.login, div.login > form  { display: flex; flex: 1; flex-direction: column; }

.horizontal-layout, div.main { display: flex; flex: 1; flex-direction: row; }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar { background-color: $themecolor1 !important; }
.mat-ink-bar { height: 4px; }
.mat-tab-label-content { text-transform: uppercase;font-family: $mainfont; }
.mat-tab-labels { background: #fff; }

// Show Hide Classes 
.menu.hide-sidebar-menu { left: -300px; }
.menu.show-sidebar-menu { left: 0px; }
.bottom-links.hide-sidebar-bottom-links { left: -300px; }
.bottom-links.show-sidebar-bottom-links { left: 0px; }
.main.show-sidebar-shrink-main { max-width: calc(100vw - 300px); margin-left: 300px; }
.main.hide-sidebar-expand-main { max-width: 100%; margin-left: 0px; }

.aside {
  & { font-family: $mainfont;width: 90%;background: #ecf0f1;padding: 0; }

  .header { width: 100%; padding: 15px 2%; margin: 0; background: #FFF;box-shadow: rgba(0,0,0,0.2); text-transform: uppercase; }
  .content { margin: 20px; text-align: justify; }
  .actionBtn { display: flex; justify-content: flex-end; margin: 20px; }
}

.navbar {
  & { display: flex;flex-direction: column;width: 340px;background: #ced6e0; }
  a { & { display: block; width: 80%; margin: 0 auto; color: #FFF; text-decoration: none; font-size: 1.2em; padding: 5px 10px; border-bottom: 1px solid #3d8fb6; border-top: 1px solid #277499; font-family: $mainfont; }
      &:first-of-type { border-top: none; }
      &:last-of-type { border-bottom: none; }
      &:hover { color: #ecf0f1; }
  }
}

.App { display: flex; font-family: $mainfont, sans-serif; }

/* -- Menu Main -- */
/* -- ========= -- */
.menu {

    & { position: fixed; width: 300px; height: 100vh; flex: 0 1 300px; background: $themecolor1; color: #fff; transition: .3s; overflow-y: auto; }
    .logo-arrow-container {
      & { display: flex; padding: 0 15px; transition: .5s; }
      .company-logo {
        & { text-align: center; margin: 10px auto;}
        img { width: 140px; transition: .5s; }
      }
      .company-logo-closed {
        img { width: 56px; transition: .5s; }
      }
      .sidebar-arrow {
        img { width: 17px; cursor: pointer; }
        .right-arrow-logo { cursor: pointer; display: flex; justify-content: center; }
        .left-arrow-logo { cursor: pointer; display: flex; justify-content: center; }
        svg { font-size: 2rem; }
      }
    }
    .greeting{ text-align: center; font-weight: 700; font-size: 24px; color: $themecolor2; }
    nav { margin: 20px 12%; margin: 0; }

    nav { & { position: relative; }
      a { display: flex; width: 100%; margin: 0 auto; color: #ffffff; text-decoration: none; font-size: 1em; font-weight: 400;padding: 8px 5px; background: none; margin: 0 auto; }
      .MuiSvgIcon-root { margin-top: -2px; margin-right: 10px; }
      a:first-of-type {border-top: none;}
      a:last-of-type {border-bottom: none;}
      a:hover {color: #F2D466; }
      .welcome-user { text-align: center; font-size: 24px; font-weight: 600; font-family: $fontmontserrat; }
      .logout { cursor: pointer; }
      h4.assessments-header { margin: 0; font-family: $fontmontserrat; font-weight: 400; font-size: 18px; padding: 0 5%; letter-spacing: 1px; }
      .assessments-list { & { margin: 0 auto; height: calc(100vh - 400px); background-color: rgba(255,255,255,0.05); overflow-y: auto; margin: 10px 0; overflow-x: hidden; }
        &::-webkit-scrollbar { width: 4px; }
        &::-webkit-scrollbar-track { box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
        &::-webkit-scrollbar-thumb { background-color: #FBF9F2; border-radius: 20px; outline: 1px solid slategrey; }
        ul { & { padding: 0; list-style-type: none; }
          .assessment-item { & { padding: 10px 7.5%; padding: 10px; cursor: pointer; font-family: $fontmontserrat; font-weight: 400; font-size: 17px; line-height: 22px; text-transform: capitalize; display: flex; align-items: center; }
            &:hover { color: #F2D466; 
              .assessment-num { background-color: #F2D466; }
            }
            .assessment-num { background-color: #FBF9F2; color: #000000; font-family: $fontmontserrat; font-weight: 600; font-size: 17px; display: flex; align-items: center; justify-content: center; width: 32px; height: 32px; text-align: center; border-radius: 50%; padding: 5px; margin-right: 10px; }
          }
          .active-assessment { & { background-color: #0F131D; color: #F2D466; font-weight: 600; font-size: 16px; }
            .assessment-num { background-color: #F2D466; }
          }
          .active-assessment.hide-active { color: #fff; background-color: rgba(255,255,255,0); font-weight: 400; }
        }
      }
      .bottom-links { 
        & { color: $themecolor2; bottom: 0; margin-left: 12px;  margin-bottom: 5px; width: auto; left: 0; }
        //& { bottom: 0; margin: 0 10px 10px 20px; width: auto; position: fixed; left: 0; } // position: fixed; }
        // .lower-icon { margin-right: 10px; background: #fff; border-radius: 20px; display: flex; justify-content: center; align-items: center; height: 24px; width: 24px; }
        .links-container {
          & { height: 100%; color: $themecolor2; }
          .nav-link { color: $themecolor2; }
          a.logout { color: $themecolor2; text-wrap: nowrap; }
        }
        .MuiSvgIcon-root { margin: 0; }
      }
      span {
        &.fade-out { opacity: 1; transition: opacity 1s; }
        &.fade-in { opacity: 0; transition: opacity 1s; }
      }
      .account { height: calc(100vh - 295px); background: none; }
    }


    .menulogo { height: 32px;margin: 6px 0 2px -20px; }

    /*.container {height: 100%;}
    nav h2 {color: #fff;font-size: 1.5em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 800;}
    nav h3 {color: #fff;font-size: 1.2em;letter-spacing: 0.1em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 600;}
    nav a {display: block;width: 90%;margin: 0 auto;color: #fff;text-decoration: none;font-size: 1.1em;font-weight: 300;padding: 15px 5px;background: none;border-bottom: 1px solid $menudividerlight;border-top: 1px solid $menudividerdark;}
    nav .profile {background: $themeaccentcolor1;padding: 20px;text-align: center;font-size: 1.4em;display: flex;align-items: center;}
    nav .profile .profilepic {flex: 0 0 60px;height: 47px;border-radius: 50%;background: $themecolor1;padding-top: 13px;}
    nav .profile .profiledetails {flex: 1 1 auto;font-size: 14px;text-align: left;line-height: 16px;margin-left: 10px;}
    nav .profile .profiledetails span {font-size: 12px;}
    nav .submenu {padding: 5px 5px 5px 20px;font-size: 0.9em;border-top: 1px solid $submenudividerlight;border-bottom: 1px solid $submenudividerdark;}
    nav .sub {background: $themeaccentcolor1;}
    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: #ecf0f1;}
    nav a i {display: block;float: left;margin-top: -4px;margin-right: 20px;font-size: 28px;opacity: 0.3;}
    .logo { padding: 15px 10px; width: 90%; border: none; line-height: 0; }
    .logo img { width: 150px; }*/
}

.close-sidebar { width: 90px; }

.login-register {
  & { background: $themecolor1; display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 60px; height: 100vh; width: calc(100% + 300px); margin-left: -300px; }
  .login {
    & { margin-top: 0; display: flex; flex-direction: column; align-items: center; justify-content: center;}
    img.bleat-logo { max-width: 140px; width: 90%; }
    .form-heading { 
      h1 { font-family: 'Butler'; font-style: normal; font-weight: 700; font-size: 48px; line-height: 58px; text-align: center; color: $themecolor2; margin-top: 75px; margin-bottom: 4px; }
      p { font-family: 'Montserrat'; font-style: normal; font-weight: 600; font-size: 24px; line-height: 29px; text-align: center; color: $themecolor2; margin: 0; }
    }
    .form-wrapper { & { margin: 63px 0px 0px; }
      form { margin-bottom: 0px; }
      .helper-text { font-family: 'Montserrat'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: $themecolor2; position: absolute; margin-left: -120px; }
      .password-container {
        & { display: flex; align-items: center; background: #f5f5f5; border-radius: 4px; }
        svg { color: #bebebe; }
        .MuiOutlinedInput-notchedOutline { border: none; }
        .eye-icon { padding: 10px; display: flex; align-items: center; }
      }
      .MuiGrid-root {  max-width: 320px; }
      .MuiGrid-spacing-xs-4 { width: calc(100% + 16px); }
      .MuiGrid-spacing-xs-4 > .MuiGrid-item { padding: 8px; }
      .MuiFormLabel-root { font-size: 14px; } 
      .MuiInputBase-root { font-family: 'Montserrat'; font-weight: 600; }
      .MuiOutlinedInput-input { font-size: 14px; background: #fff; border-radius: 3px; }
    }
    .forgot-password {
      & { font-family: 'Montserrat'; font-style: italic; font-weight: 500;font-size: 16px; line-height: 20px; color: #FFFFFF; margin-left: -4px; }
      a { color: #F2D466; text-decoration: none; cursor: pointer; }
    }
    .sign-in {
      & { font-family: 'Montserrat'; font-style: italic; font-weight: 500;font-size: 16px; line-height: 20px; color: #FFFFFF; margin-left: -16px; }
      a { color: #F2D466; text-decoration: none; cursor: pointer; }
    }
    .remember-forgot-wrapper {
      & { margin: 10px 0px 30px; display: flex; flex-wrap: wrap; justify-content: space-between; }
      a { color: #007C89; text-decoration: none; font-size: 14px; font-weight: 400; }
    }
    .no-account-wrapper {
      & { margin-top: 20px; color: #292F36; font-size: 16px; font-weight: 400; }
      a { color: #007C89; text-decoration: none; }
    }
    .outlineBtn { margin: 2rem auto; margin-left: -8px; width: 100%; background: $themecolor2; color: #fff; border-radius: 3px; font-family: 'Montserrat'; font-style: normal; font-weight: 700; height: 54px; font-size: 18px; line-height: 22px; text-transform: uppercase; }
    .visibility { cursor: pointer; }

  }

}

.question-delete-modal {
  .MuiBox-root {
    & { border-radius: 5px; }
    .add-goal-form {
      & { max-width: 400px; max-height: 232px; }
      .heading-and-close-icon-container {
        & { display: flex; }
        .close-icon { 
          & { width: 24px; cursor: pointer; }
          svg {
            & { width: 24px; height: 24px; } 
            path { fill:#000000; }
          }
         }
        p { font: normal normal 600 20px/24px $fontmontserrat; letter-spacing: 1px; color: #000000; margin-top: 0; }
      }
      .form-btns {
        & { display: flex; flex-direction: column; gap: 16px; }
        .delete-btn { background-color: #122845; color: white; text-transform: capitalize; text-align: left; font: normal normal medium 20px/24px $fontmontserrat; letter-spacing: 0px; color: #FFFFFF; }
        .cancel-btn { color: #122845; border: 2px solid #122845; text-transform: capitalize; text-align: left; font: normal normal medium 20px/24px $fontmontserrat; letter-spacing: 0px; }
      }
    }
  }
}

.category-delete-modal {
  .MuiBox-root {
    & { border-radius: 5px; }
    .add-goal-form {
      & { max-width: 400px; max-height: 232px; }
      .heading-and-close-icon-container {
        & { display: flex; }
        .close-icon { 
          & { width: 24px; cursor: pointer; }
          svg {
            & { width: 24px; height: 24px; } 
            path { fill:#000000; }
          }
         }
        p { font: normal normal 600 20px/24px $fontmontserrat; letter-spacing: 1px; color: #000000; margin-top: 0; }
      }
      .form-btns {
        & { display: flex; flex-direction: column; gap: 16px; }
        .delete-btn { background-color: #122845; color: white; text-transform: capitalize; text-align: left; font: normal normal medium 20px/24px $fontmontserrat; letter-spacing: 0px; color: #FFFFFF; }
        .cancel-btn { color: #122845; border: 2px solid #122845; text-transform: capitalize; text-align: left; font: normal normal medium 20px/24px $fontmontserrat; letter-spacing: 0px; }
      }
    }
  }
}


.main {
  & { height: 100vh; flex: 1 1 auto; background-color: #FBF9F3; color: $titlecolor1; margin-left: 300px; transition: .5s; }
  h1 { color: $titlecolor1; }
  h1 a { color: $titlecolor1; text-decoration-thickness: 2px; text-decoration-color: #c8c8c8; }

  .container { 
    
    & { width: 92%; height: 96vh; padding: 0 4% 4vh; overflow-y: auto; }

    header {       
      & { display: flex; align-items: center; justify-content: space-between; }
      .header-cta { display: block; cursor: pointer; background: #007C89; color: #fff; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; }
    }

  }
  .clients, .container {
    .card-container { 
      &{width: 100%; text-align: center; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); justify-content: center; grid-gap: 20px; margin: 0 auto; }
      // .MuiTable-root { width: 80%; padding: 20px 30px;}
      .card-table { }
      .card {
        & {display: flex; flex-direction: column; align-items: center; width: calc(100% - 60px); padding: 20px 30px; min-width: 300px; }
        .card-title {font-size: 22px; font-weight: 700; margin-bottom: 25px; }
        .logo-img { width: 100%; height: 100px; margin-bottom: 25px; border-radius: 5px; display: flex; justify-content: center; align-items: center; }
        .logo-img img { border-radius: 5px; height: auto; max-height: 120px; width: auto; max-width: 300px; }
        .logo { width: 100%; height: 100px; margin-bottom: 25px; padding: 5px 0; border: 2px dashed #92929292; border-radius: 5px; display: flex; flex-direction: column; justify-content: center; align-items: center; }
        .logo .MuiSvgIcon-root { font-size: 70px !important; opacity: 0.7; }
        .card-stats {font-size: 20px; display: flex; width: 80%; justify-content: space-evenly; font-weight: 600; margin-bottom: 25px;}
        .icons {display: flex; justify-content: space-evenly; width: 80%;}
      }
    }
  }
  
  .MuiTable-root { 
    & { color: $titlecolor1; font-family: $mainfont, sans-serif; }
    a { color: $titlecolor1; }
    h3 { padding: 0; margin: 0; }
    .no-underline { text-decoration: none; }
    .assessment-link { cursor: pointer; }
    .assessment-link:hover { text-decoration: underline; }
  }
  .MuiTableCell-head { color: $titlecolor1; font-weight: 700; font-family: $mainfont, sans-serif; }
  .MuiTableCell-body { & { color: $titlecolor1; font-family: $mainfont, sans-serif; }
    .variables-container { 
      & { display: flex; align-items: center; justify-content: center; }
      .text-icon-container {
        & { width: 24px; height: 24px; }
        .text { margin: 0; }
        img { width: 24px; }
      }
    }
    .order-and-drag-container { display: flex; align-items: center; }
    .question-text-icon-container { display: flex; gap: 10px;}
    .type-txt{ 
      &{ display: flex; align-items: center; gap: 10px; }
        p{ text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #0F131D; }
    }
    .edit-and-trash-icon {
      &{ display: flex; align-items: center; gap: 8px; font-size: 1.2rem; }
    }
  }

  .table-tool { cursor: pointer; }
  .table-tool-small { cursor: pointer; margin: 6px 0 -6px 0; }

}

.MuiDrawer-paper {  }
.container .create-assessment-drawer{width: 100vw;}
.drawer {

  & { width: 90vw; }
  header { width: calc(100% - 60px); padding-left: 30px; }
  .react-select__menu { & { background-color: #ffffff; z-index: 2; }
  }
  .MuiTableCell-head { text-align: left; font: normal normal normal 14px/18px $fontmontserrat; letter-spacing: 0px; color: #5B5B5B; opacity: 1; }
  .MuiTableCell-body { color: $titlecolor1; font-family: $mainfont, sans-serif; width: 10%; }
  .table-tool { fill: #000000; cursor: pointer; }
  .MuiTabs-flexContainer { background: #fff; }

  section { background: #fff; padding: 30px; padding-top: 0px; min-height: calc(100vh - 130px); width: calc(100% - 60px); overflow-y: auto; }
  .double-column-form { 
    & { display: flex; align-items: center; gap: 20px; margin-bottom: 30px; }
    label { text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #212121; }
    .MuiInputBase-fullWidth { width: 98%; }
    #file-type { top: -5px; left: 15px; }
    .cancel-button-add-category-subdrawer{
      &{ border: 2px solid #122845; border-radius: 4px; background: transparent; padding: 3px 18px; font-weight: 600; }
    }
    .submit-btn{ background-color: #122845; color: white; }
  }

  .edit-category-btn-container { 
    .cancel-btn { font-family: "Montserrat", sans-serif; background-color: #eb4d4b; color: #fff; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; }
  }

  .button-container {
    &{ display: flex; justify-content: flex-end; gap: 40px; align-items: center; margin-top: 20px; }
    .cancel-btn { text-align: left; font: normal normal bold 16px/21px $fontLato; letter-spacing: 0px; color: #122845; width: max-content; padding: 6px 85px; border: 2px solid #122845; }
    .add-question-btn { background: #122845; color: #fff; border-radius: 4px; padding: 7px 32px; font: normal normal bold 16px/21px "Lato", sans-serif; }
  }

  .margin-top { margin-top: 15px; }
  .aligned-right { justify-content: flex-end; margin-right: 1%; }

  .tab-title { text-transform: capitalize; font-family: $fontmontserrat; font-weight: 400; color: #5B5B5B; font-size: 16px; }
  .tab-title.Mui-selected { font-weight: 600; color: #1F2A44; }


  // User Details Drawer
  .user-details-header { & { display: flex; flex-direction: row; justify-content: space-between; align-items: center; }
    h1 { font-family: $fontbutler; font-size: 28px; margin: 32px auto 16px 0; letter-spacing: 1px; text-transform: capitalize; color: #1F2A44; }
    .close-user-details-drawer { cursor: pointer; }
  }
  .referral-link-container { & { display: flex; justify-content: flex-start; align-items: center; }
    .referral-link-text { color: #1F2A44; font-weight: 600; font-size: 16px; font-family: $fontmontserrat; padding-right: 10px; }
    .referral-link .MuiInputBase-root { border-radius: 30px; overflow: hidden; margin-right: 10px; }
    .referral-link input { padding: 10px 15px; background-color: #F4F4F4; color: #919191; font-family: $fontmontserrat; width: 24ch; }
    .copy-referral-btn { background-color: #212A42; color: #FFFFFF; font-family: $fontmontserrat; font-size: 14px; font-weight: 600; border-radius: 30px; padding: 7px 15px; }
    .copy-referral-btn.copied { color: #212A42; background-color: #F2D466; }
    .copy-referral-btn.copied:hover { color: #212A42; background-color: #F2D466; }
    .copy-referral-btn:hover { background-color: #212A42; color: #FFFFFF; }
  }
  .user-personal-container { display: grid; grid-template-columns: 1fr 1fr; }
  .info-section { & { margin: 15px 0; font-family: $fontmontserrat; }
    .info-title { font-weight: 700; color: #343333; font-size: 16px; margin: 5px 0; }
    .info-value { font-size: 18px; font-weight: 400; margin: 5px 0; }
    .add-question-btns { font-family: $fontLato; }
  }
  .user-score-container { display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; }
  .user-score-container a { text-decoration: none; }
  .score-section { & { margin: 30px; font-family: $fontmontserrat; }
    .score-title { font-weight: 700; color: #343333; font-size: 16px; margin: 10px; }
    .score-value { font-size: 21px; font-weight: 400; margin: 10px; } 
  }

  .user-score-details { 
    a { text-decoration: none; }
    .score-section { padding: 15px 0; }
    .view-results-btn { background-color: #212A42; color: #ffffff; margin: 15px 30px; padding: 15px 25px; font-size: 14px; font-family: $fontmontserrat; }
    .additional-questions-answers { & {}
      h3 { font-size: 21px; }
      .qa-container {
        p { font-size: 18px; max-width: 1100px; }
        .question { margin: 18px 0 8px; padding: 5px 0 0; font-weight: 700; }
        .answer { margin: 8px 0 18px; }
        .left-text { display: inline-block; width: 4ch; text-align: center; font-weight: 700; }
      }
    }
  }
  .display-block {display: block; margin-bottom: 15px;}
  .user-referrals { & {  }
    table { & {  }
      // thead tr th { text-align: center; }
      th { font-family: $fontmontserrat; font-weight: 700; color: #343333; font-size: 16px; margin: 10px; }
      // th:nth-child(4) { text-align: center; }
      td { font-family: $fontmontserrat; font-weight: 400; font-size: 16px; color: #1F2A44; }
      .referral-info-link { text-transform: capitalize; }
      th a { color: #1F2A44; font-weight: 400; }
      .copy-referral-btn { background-color: #212A42; color: #FFFFFF; font-family: $fontmontserrat; font-size: 14px; font-weight: 600; border-radius: 4px; padding: 7px 15px; text-transform: capitalize; width: max-content; }
      .copy-referral-btn.copied { color: #212A42; background-color: #F2D466; }
      .copy-referral-btn.copied:hover { color: #212A42; background-color: #F2D466; }
      .view-results-link { text-decoration: none; }
      .view-results-link button { background-color: #212A42; color: #FFFFFF; font-family: $fontmontserrat; font-size: 14px; font-weight: 600; border-radius: 4px; padding: 7px 15px; text-transform: capitalize; width: max-content; }
      .assessment-status { font-weight: 600; }
      .assessment-complete { color: #0AC74A; }
      .assessment-pending { color: #EB7A24; }
    }
    table .MuiTableCell-body { width: auto; }
  }
  .react-select {left: 5px;}
  .react-select__value-container {height: 55px;}
  
  .complete-create-assessment {
    .MuiSvgIcon-root {height: 150px; font-size: 150px; color: green;}
    .MuiButton-containedPrimary{ background-color: #212A42;}
    .MuiButton-root {font-weight: 600; font-family: $mainfont;}
  }
  .complete-create-assessment.MuiButton-containedPrimary{ background-color: #212A42;}
  .complete-create-assessment.MuiButton-root {font-weight: 600; font-family: $mainfont;}

  .google-sheets { & {  }
    .form-headers { & { display: flex; box-shadow: 0px 2px 10px #00000014; border-radius: 8px; font-family: $fontmontserrat; }
      .form-header { & { display: flex; flex-direction: column; text-align: center; margin-left: 20px; cursor: pointer; margin-top: 10px; } 
        label { color: #9B9B9B; font-weight: 700; font-size: 14px; }
        small { color: #9B9B9B; font-size: 10px; font-weight: 600; }
      }

      .form-header-active { & { display: flex; flex-direction: column; text-align: center; margin-left: 20px; margin-top: 10px; border-bottom: 4px solid #F2D466; cursor: pointer; } 
        label { color: #000000; font-weight: 700; font-size: 14px; }
        small { color: #000000; font-size: 10px; font-weight: 600; margin-bottom: 5px; }
      }
    }

    .row-section { & { display: flex; margin-top: 20px; font-family: $fontmontserrat; }
      .MuiFormGroup-root { & { display: flex; flex-direction: row; }
      .MuiFormControlLabel-label { color: #000000; font-size: 16px; font-weight: 700;}
    }
  }

    .columns { & { margin-top: 20px; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; font-family: $fontmontserrat; }
    .column { & { display: flex; flex-direction: column; margin: 10px 2px; }
      label { color: #212121; font-weight: 700; font-size: 16px; margin-bottom: 2px; }
      .MuiInputBase-input { border: 1px solid #BEBEBE; height: 26px; min-width: 480px; border-radius: 4px; font-family: $fontmontserrat; font-weight: 500; font-size: 16px; padding-inline-start: 5px; }
      .MuiInput-underline::before { border-bottom: none; }
      .MuiInput-underline::after { border-bottom: none; }
    }
  }

  .field-and-columns { & { margin-top: 20px; display: flex; flex-direction: row; justify-content: space-between; overflow-y: scroll; scroll-behavior: smooth; }
    .column-data { & { display: flex; flex-direction: column; }
    .text-field { & { justify-content: start; height: min-content; }
    .MuiTouchRipple-root { border-bottom: 3px solid #4D9950; }
      }
      .add-text-field { justify-content: start; height: 45px; width: 160px; margin: 5px; border-radius: 4px; border: 1px solid #BEBEBE; background-color: #FFFFFF; display: flex; align-items: center; font-size: 24px; cursor: pointer; }
      .text-field-input input { font-size: 18px; }
      .MuiButton-root { height: 45px; width: 160px; margin: 5px; }
      .MuiButton-label { font-weight: 700; }
      .MuiInputBase-input { border: 1px solid #BEBEBE; height: 26px; width: 156px; border-radius: 4px; font-family: $fontmontserrat; font-weight: 500; font-size: 16px; padding-inline-start: 5px; margin-top: 5px; }
      .MuiInput-underline::before { border-bottom: none; }
      .MuiInput-underline::after { border-bottom: none; }
      .MuiInput-input { font-weight: 600; font-size: 24px; color: #000000; }
      .MuiFormControl-root { margin: 5px; }
  }
    .add-columns { & { padding: 5px; height: 105px; }
    .MuiButton-label { font-weight: 600; font-size: 24px; justify-content: start; align-items: center; }
  }  

 }
}

}

.add-category-drawer{
  .drawer{ width: 80vw; }
}

.w60 {width: 60%; margin: auto;}



.drawer.user-details { & { width: 55vw; width: 80vw; }
  section { padding-top: 0; }
}

.link-copied-snackbar { .MuiSnackbarContent-root { min-width: 30ch; justify-content: center;  }}

.create-page-modal-container.MuiBox-root { width: 550px; }
.create-assessment-page-modal-container.MuiBox-root { width: 50vw; height: 55vh; overflow: hidden; border-radius: 5px; }
.delete-assessment-page-modal-container.MuiBox-root { width: 500px; height: 100px; overflow: hidden; border-radius: 5px; }
.modal {

  & { font-family: $mainfont, sans-serif; }

  .tox-tinymce-inline { z-index: 9999; }

  .footer {
    & { display: flex; justify-content: space-between; }
    //.cancel-btn { background: #fff; }
    //.cancel-btn:hover { background: #efefef; }
    .cancel-btn { font-family: $fontmontserrat; background-color: $buttoncolor1; color: #fff; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; }
    .cancel-btn:hover { background-color: $buttonaccentcolor1; color: #fff; }
    .confirm-btn { font-family: $fontmontserrat; background-color: #007C89; color: #fff; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; }
    .confirm-btn:hover { background-color: #00a5b8; color: #fff; }
  }

  // for drawer nested inside modal 
  .drawer { & { width: auto;}
    header { & { padding: 0; }
      h1 { transition: all 0.1s; }
    }
    section { padding: 0; min-height: auto; width: 100%; }
  }
  
}

.crop-container {
  & { width: 500px; height: 450px; width: 900px; height: 550px; display: flex; flex-direction: column; align-items: center; justify-content: space-between;}
  .title { width: 100%; display: flex; align-items: center; justify-content: space-between; margin: 0 15px; margin-right: 25px;
    h2 { margin: 0; color: #4D5865; }
    .MuiSvgIcon-root { color: #929292; border: 2px solid #929292; border-radius: 10px; cursor: pointer;}
  }
  .easy-crop {
    & { margin: 10px 0; position: relative; height: 72%; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
    .d-n-d { 
      & { height: 70%; width: 90%; border: 2px dashed #929292; border-radius: 25px; display: flex; align-items: center; justify-content: center;}
      .MuiButtonBase-root { height: 36px; background: #212A42; color: #fff; padding: 0 15px; border-radius: 5px; font-family: 'Montserrat'; font-style: normal; font-weight: 700; }
    }
    .reactEasyCrop_Container {
      & { height: 100%; border-radius: 10px; }
    }
  }
  .crop-btns {
    & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
    .slider {
      & { margin-left: 15px; width: 35%; }
      h3 { margin: 0; color: #4D5865; }
      .MuiSlider-root {
        & {  color: #212A42; height: 6px; }
      }
    }
    .MuiButtonBase-root {
      & { width: 180px; height: 36px; background: #212A42; color: #fff; border-radius: 5px; font-family: 'Montserrat'; font-style: normal; font-weight: 700; }
      .MuiCircularProgress-root { height: 30px !important; width: 30px !important; }
      .MuiCircularProgress-root.uploading { height: 18px !important; width: 18px !important; }
    }
    .Mui-disabled { background: #efefef; color: #929292; }
  }
}

.email {
  & { padding-bottom: 10px; };
  .email-header { background-color: #F4F2EC; padding-left: 25px; padding-top: 5px; margin: 0px;}
  .email-header h1 {font-size: 24px; color: #57606F; line-height: 25px; font-family: $fontbutler; }  
    .email-link-container {
      & { display: flex; font-size: 18px; height: min-content; padding-bottom: 15px; gap: 5px; }
          p { margin: 0px; } 
         .email-link { color: #5B5B5B; line-height: 22px;} 
    } 
  .email-container {
    & { padding-top: 15px;}
    .box { margin-left: 25px;}
    .MuiDataGrid-columnHeaders { background-color: #F1F1F1 ; height: 56px; }
    .MuiTab-wrapper { color: #57606F; font-size: 14px; font-family: $fontmontserrat; }
    .Mui-selected { font-weight: 600; }
    .MuiDataGrid-columnHeaderTitleContainer {justify-content: start; }
    .MuiDataGrid-columnHeaderTitle { color: #5B5B5B; font-size: 15px; font-weight: 400; font-family: $fontmontserrat; }
    .MuiDataGrid-cell {justify-content: start; }
    .MuiDataGrid-cellContent { color: #0F131D; font-weight: 500; font-size: 14px; font-family: $fontmontserrat; white-space:break-spaces;}
    .MuiDataGrid-cell--textRight {justify-content: end; gap: 15px; }
    // .search-bar {background-color: #FBF9F3; color: #979797; padding: 10px; font-size: 16px; border: 1px solid #BEBEBE; border-radius: 4px; margin:16px; font-family: $fontmontserrat; max-width: 187px;margin-top: 20px; margin-left: 20px;} 
      .search-bar { & { position: relative; width: 250px; }
      input[type="search"].search-field::-webkit-search-cancel-button { -webkit-appearance: none; }
      .search-field { background-color: #FBF9F3; color: #979797; padding: 10px; font-size: 16px; border: 1px solid #CBCBCB; border-radius: 3px;  font-family: $fontmontserrat; width: 250px; }
      .clear-search-icon { position: absolute; right: 8px; bottom: 10px; cursor: pointer; color: #777777; height: 24px; width: 24px; font-size: 24px; }
    }
    .grid { border: 1px solid #E6E6E6; border-radius: 8px; height: 100%; margin: 20px ; }
    .MuiDataGrid-row { background-color: #ffffff; }
    .MuiDataGrid-virtualScrollerContent {background-color: #FFFFFF; border-radius: 8px; }
    .MuiDataGrid-footerContainer{ display: none; }
    .btn-container{
      &{display: flex; justify-content: space-between; align-items: center; margin: 20px;}
      .create-btn{
        &{width: 107px;height: 40px;background-color: #122845; border-radius: 4px; color: #fff; font-size: 16px; font-weight: bold; margin-right: 20px; cursor: pointer; border: none; }
      }
    }
    .MuiBox-root{padding: 0px;}
    .email-notification-table{
       .MuiDataGrid-cell {
       &{ justify-content: start; padding-left: 15px;}
       }
       .MuiDataGrid-columnHeaderTitleContainer {justify-content: start; padding-left: 5px; }
       .email-name{
        &{display: flex; flex-direction: column;}
        div{
          &{color: #0F131D;font-weight: 600;font-size: 14px;font-family: "Montserrat", sans-serif;}
        }
        }
        img{cursor: pointer;}
    }
  }
}



.edit-notification-container ,.create-notification-container{
  .modal-content{
    &{position: absolute;min-width: 674px; min-height: 854px; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white;}
     .main-content {
      & {padding: 40px; font-family: $fontmontserrat;}
      .heading-container{ display: flex; justify-content: space-between; align-items: center;}
     .heading{
      &{letter-spacing: 0px;color: rgba(33, 33, 33, 1); font-weight: bold; font-size: 30px;}
     }
     .loader-section-uploaing{position: absolute; top: 53%;  left: 50%;  transform: translate(-50%, -50%);  padding: 10px;  border-radius: 5px;    display: flex;    align-items: center;    justify-content: center;   flex-direction: column;   z-index: 11;
     }
      .label{
      &{letter-spacing: 0px; color: rgba(33, 33, 33, 1); font-size: 17px; font-weight:500; margin-bottom: 8px; font-family: $fontmontserrat;
      }
     }
     .items{
     &{  margin-top: 20px;}
     .text-box{
      width: 560px;  padding: 15px; border: 1px solid rgba(190, 190, 190, 1); border-radius: 4px; font-size: 15px;
     }
     .MuiFormGroup-root{flex-direction: row;}
     .MuiRadio-colorSecondary.Mui-checked{color: rgba(18, 40, 69, 1);}
     .MuiTypography-body1{font-size: 16px; color: rgba(33, 33, 33, 1); font-family: $fontmontserrat;}
     }
     .create-btn-submit{
        &{width:100%;height: 50px;background-color: #122845; border-radius: 4px; color: #fff; font-size: 16px; font-weight: bold; margin-top: 50px;font-family: $fontmontserrat; border: none; text-transform: uppercase; cursor: pointer;}

     }
      .MuiCircularProgress-colorPrimary {height: 30px !important;width: 30px !important;text-align: center;color: white;}

     img{cursor: pointer;}
    .upload-container {
      &{ position: relative; display: inline-block; }
       input[type="file"] { opacity: 0; position: absolute; top: 0; left: 0; height: 100%; width: 100%; cursor: pointer;}
        img { height: 100%; width: auto; display: block; max-width: 594px; max-height: 176px; }
      }
    }
  }
 
}


.modal{
  .delete-popup-content{
  &{position: absolute;max-width: 400px;min-height: 256px; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; font-family: $fontmontserrat;border-radius: 5px;}
    .main-content {
      & {padding: 24px; font-family: $fontmontserrat;}
      .heading-container{
      &{display: flex; justify-content: space-between; align-items: start; margin-bottom: 24px;}
      .heading{
      &{ letter-spacing: 1px; color: #000000; font-weight: 600; font-size: 20px;white-space: break-spaces;}
      }
      img{cursor: pointer;}
     }
     .delete-btn{
      &{width: 352px; height: 47px; background-color: #122845; border-radius: 5px; color: #fff; font-size: 16px; font-weight: 500; margin: 16px auto; cursor: pointer;font-family: $fontmontserrat; border: none; text-transform: uppercase;}
     } 
    .MuiCircularProgress-colorPrimary {height: 30px !important;width: 30px !important;text-align: center;color: white;}
    .cancel-btn{
      &{width: 352px; height: 47px; background-color: #fff; border-radius: 5px; color: #122845; font-size: 16px; font-weight: 500; border: 1px solid #122845; cursor: pointer;font-family: $fontmontserrat; text-transform: uppercase;}
     }
  }
}
}

.edithtml-popup-modal{
     .edithtml-popup-content{
     &{position: absolute;min-width: 1088px; max-height: 734px; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; font-family: $fontmontserrat;border-radius: 11px;border: 1px solid #7FB6BE;}
     .main-content {
      & {padding: 40px; font-family: $fontmontserrat;}
         .heading-container{
         &{display: flex; justify-content: space-between; align-items: center; margin-bottom: 24px;}
          .heading{
          &{ letter-spacing: 0px; color: #000000; font-weight: bold; font-size: 25px;}
          }
          img{cursor: pointer;}
         }
         .label{color: #212121; font-weight:500; font-size: 16px; margin: 5px auto;}
         textarea{width: 100%;  height: 440px !important; border: 1px solid #BEBEBE; border-radius: 4px;}
         .edit-btn{
          &{width: 100%;height: 50px; background-color: #122845; color: #ffffff;border-radius: 4px; margin: 48px auto; font-size: 16px; font-weight: bold; border: none; cursor: pointer;text-transform: uppercase;}
         }
        .upload-container {
       &{ position: relative; display: inline-block; }
       input[type="file"] { opacity: 0; position: absolute; top: 0; left: 0; height: 100%; width: 100%; cursor: pointer;}
        img { height: 100%; width: auto; display: block; max-width: 594px; max-height: 176px; }
      }
      .MuiCircularProgress-colorPrimary {height: 30px !important;width: 30px !important;text-align: center;color: white;}

      }
  }
}

.send-popup-modal{
 .send-popup-content{
  &{position: absolute;min-width: 534px; width: 100%; min-height: 854px; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; font-family: $fontmontserrat;border-radius: 5px;}
    .main-content {
      & {padding: 24px; font-family: $fontmontserrat;}
      .heading-container{
      &{display: flex; justify-content: space-between; align-items: start; margin-bottom: 35px;}
      .heading{
      &{ letter-spacing: 1px; color: #000000; font-weight: 600; font-size: 20px;}
      }
      img{cursor: pointer; height: 100%;}
     }
     label{color: #212121; font-size: 16px; font-weight: 500; margin-bottom: 5px;}
     input{width: 95%;height: auto; background-color: #FBF9F3; color: #000000;  padding: 10px;  font-size: 16px;   border: 1px solid #CBCBCB;   border-radius: 3px; font-family: "Montserrat", sans-serif;}
      input[type="text"] {-webkit-appearance: none; }
      .form-group{
      &{
        display: flex; flex-direction: column; margin-bottom: 15px;
      }
     }
      .MuiCircularProgress-colorPrimary {height: 30px !important;width: 30px !important;text-align: center;color: white;}

     .delete-btn{
      &{width: 100%; height: 50px; background-color: #122845; border-radius: 5px; color: #fff; font-size: 14px; font-weight: 500; margin: 16px auto; cursor: pointer; margin-top: 35px;font-family: $fontmontserrat; border: none;text-transform: uppercase;}
     } 
     .cancel-btn{
      &{width: 100%; height: 50px; background-color: #fff; border-radius: 5px; color: #122845; font-size: 14px; font-weight: 500; border: 1px solid #122845; cursor: pointer;font-family: $fontmontserrat;text-transform: uppercase; }
     }
     .email_chip{
      input[type=text] {
        border: none;
        height: 8px;
      }
     }
  }
}
}


.assesment-container{
   &{ position: fixed;  left: 0;  top: 0;  width: 100%;height: 100%;transform: scale(1.1); transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; z-index: 3;background-color: rgba(0, 0, 0, 0.5);
  }  .modal-content{
    &{position: absolute;min-width: 674px; min-height: 666px; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; border: 1px solid #7FB6BE;border-radius: 11px;}
     .main-content {
      & {padding: 40px; font-family: $fontmontserrat;}
      .heading-container{ display: flex; justify-content: space-between; align-items: center;}
     .heading{
      &{letter-spacing: 0px;color: rgba(33, 33, 33, 1); font-weight: bold; font-size: 25px;}
     }
      .label{
      &{letter-spacing: 0px; color: rgba(33, 33, 33, 1); font-size: 17px; font-weight:500; margin-bottom: 4px; font-family: $fontmontserrat;
      }
     }
     .items{
     &{  margin-top: 20px;}
     .text-box{
     &{ width: 96%;  padding: 10px; border: 1px solid rgba(190, 190, 190, 1); border-radius: 4px; font-size: 15px;}
     }
     .text-select{
     &{ width: 100%;  padding: 10px; border: 1px solid rgba(190, 190, 190, 1); border-radius: 4px; font-size: 15px;}

     }
     .MuiFormGroup-root{flex-direction: row;}
     .MuiRadio-colorSecondary.Mui-checked{color: rgba(18, 40, 69, 1);}
     .MuiTypography-body1{font-size: 16px; color: rgba(33, 33, 33, 1); font-family: $fontmontserrat;}
     }
     .create-btn-submit{
        &{width: 594px;height: 56px;background-color: #122845; border-radius: 11px; color: #fff; font-size: 16px; font-weight: bold; margin-top: 50px;font-family: $fontmontserrat; border: none; cursor: pointer;}

     }
     .MuiOutlinedInput-root{max-height: 40px;}
     img{cursor: pointer;}
       .upload-container {
      &{ position: relative; display: inline-block; }
       input[type="file"] { opacity: 0; position: absolute; top: 0; left: 0; height: 100%; width: 100%; cursor: pointer;}
        img { height: 100%; width: auto; display: block; max-width: 594px; max-height: 176px; cursor: pointer; }
      }
   
    }
  }
}






.preview{

    &{ position: fixed;  left: 0;  top: 0;  width: 100%;height: 100%;transform: scale(1.1); transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; z-index: 3;
  }
  .modal-content{
    &{position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
     .main-content {
      img{ height: 200px;}
          .heading-container{
      &{display: flex; justify-content: flex-end; align-items: center; }
      img{height: 20px; cursor: pointer;}
          }
     
    }
  }
}















// main h1 {margin: 10px 0 10px 0;font-size: 2.5em;font-weight: 800;text-transform: uppercase;color: #3c6382;}

// Main container and their styles
// -------------------------------
main {

  // & { width: 1}

    // Main toolbar
    .pagetitle {background: transparent !important;width: 100%;}
    .mat-toolbar {background: transparent !important;}
    .mat-toolbar-row h1 {margin: 10px 0 10px 0;font-size: 1.5em;font-weight: 800;text-transform: uppercase;color: $titlecolor1;font-family: $mainfont;}
    .mat-toolbar-row button {font-weight: 800;font-family: $mainfont;text-transform: uppercase;margin-right: 5px;}

    .table-container {display: flex;flex-direction: column;min-width: 300px;background: $themecolor4;border-top: 2px solid $themecolor1;}

    // Mat Table
    // ---------
    .mat-table {font-family: $mainfont;overflow: auto;max-height: 80vh;}
    .mat-header-row {position: sticky;top: 0;background-color: #fff;z-index: 1000;}
    .mat-header-cell {font-size: 1em;color: $themecolor1;font-family: $mainfont;text-transform: uppercase;font-weight: 800;}
    .mat-menu-item {font-family: $mainfont;letter-spacing: 0;font-size: 12px;padding: 0 8px !important;height: auto !important;line-height: 36px !important;margin-bottom: 0;}

    .draft {background: #e55039;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .running {background: #78e08f;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .closed {background: none;color: #78e08f;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}

}

.mat-column-responses {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-status {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-tools {flex: 0 0 80px !important;text-align: right;}



app-lightbox { display: flex; justify-content: center; position: fixed;  width: 100%; height: 100%;
  background: rgba(0,0,0,0.5);  z-index: 10000; font-family: $mainfont; left: 0px; overflow-y: scroll; }

  .openlightbox { opacity: 1 !important; }

  .lightbox {
    & { position: absolute; border-radius: 30px; text-align: center; padding: 0px; background: #FFF; max-width: 680px; z-index: 10001; min-height: 100px; top: 64px; font-family: $mainfont;  left: 50%; transform: translate(-50%, 0px); }

    h1 { color: #1F4E96; font-size: 1.5em; margin: 0px;
    padding: 20px 0px; border-top-right-radius: 30px; border-top-left-radius: 30px; }

    h2 { font-size: 1.4em;color: #1F4E96; font-weight: 800; font-family: "proxima-nova", sans-serif; }

    .lightboxfooter { justify-content: space-between !important; padding: 15px 30px; }
    .lightboxfooter button { font-family: $mainfont; background: #f1f2f6; font-weight: 600; }

    form { margin-bottom: 0px; }
    .mat-form-field { width: 100%; }
  }

  .defaultLightbox > div { padding: 30px;
    font-family: $mainfont;
    &:last-of-type { display: flex;  justify-content: flex-end; }
    > p { text-align: justify; }
  }



// Snackbar container
// ----------------
.mat-snack-bar-container {margin: 20px !important;}
.mat-snack-bar-container-failure {background-color: $bg-failure;}
.mat-snack-bar-container-success {background-color: $bg-success;}
.mat-snack-bar-container-pending {background-color: $bg-pending;color: #fff;}

// ========================= Smiler CSS ============================== //

// =================== Global CSS ===================



// theme colors
$smilercolor1: #FFEB33;

// background
$bgmain: #f6f7fa; // body

// form
$formtext: #1F4E96;
$formbg: #eff5ff;

// classes
body { background-color: $bgmain; font-family: $mainfont; }
.full-frame { max-width:1440px; margin:0 70px; width: calc(100% - 140px); }
.medium-frame { max-width:991px; margin:0 70px; width: calc(100% - 140px); }
.cdk-overlay-container { z-index: 10002; }

// spinner
.matspinner {display: flex;justify-content: center;align-items: center;min-height: 50vh;}

// buttons
.yellowBtn { background-color: $smilercolor1 !important; color:#292F36 !important; font-family: $mainfont; }
.outlineBtn { background-color: none !important; border: 1px solid #292F36 !important; font-family: $mainfont; }
.yellowBtn.mat-button-disabled { opacity: 0.5; }
.plainBtn { background-color: #ffffff !important; color:#007C89 !important; font-family: $mainfont; }
.plainBtn.mat-button-disabled { opacity: 0.5; }
.btn-large { box-shadow: none !important; width: 100%; border-radius: 12px !important; height: 48px; font-size: 16px; font-weight: 700; }
.btn-small { box-shadow: none !important; min-width: 120px !important; border-radius: 12px !important; height: 48px; font-size: 16px; font-weight: 700; }

.smiler-button-loader {
  & { transition: 0.1s !important; }
  &.smiler-button-loading { min-width: 50px !important; height: 50px !important; border-radius: 100% !important; padding: 0px !important; }
  .submit-loader { width: 40px; }
}

// form field
.form-field {
  .field-label { color: #292F36; font-size: 16px; font-weight: 500; }
  .mat-form-field { width:100%; }
  input[type="password"], input[type="text"] { border: none !important; padding: 0px; height: auto; background-color: transparent; }
  .mat-form-field {
    & { width:100%; font-family: $mainfont; }
    // .mat-form-field-wrapper { margin: 0; padding: 0; }
    .mat-form-field-infix { padding: 12px 0 20px 0; border-top-width: 12px; }
    .mat-form-field-outline-start, .mat-form-field-outline-end { border-width: 0; }
     input::placeholder { font-size: 14px; color: #D0D5DB; font-family: $mainfont; }
     input { font-size: 14px; color: $formtext !important; font-family: $mainfont; }
     textarea { border:none;  font-size: 14px; color: $formtext; }
     textarea::placeholder { color: #D0D5DB; }
     .mat-form-field-outline { background: #F2F4FC; border-radius: 12px; }
     .mat-form-field-outline-thick .mat-form-field-outline-start,
     .mat-form-field-outline-thick .mat-form-field-outline-end,
     .mat-form-field-outline-thick .mat-form-field-outline-gap {
      border-width: 0px ;
      }
      .mat-form-field-suffix { top: 7px; }
      .mat-form-field-suffix .material-icons { color: #E2E7F4; }
      .mat-form-field-suffix .material-icons.valid { color: #718194; }
      .mat-error { font-weight: 600; }
   }
}

// checkbox
.circle-checkbox {
  .mat-checkbox-frame { border-color: #C7C9D9; border-radius: 100%; border-width: 1px; background-color: #F2F2F5 ; }
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, &.mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: #FFFD00; border-radius: 100%; }
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element  { background: #FFFD00; }
  .mat-checkbox-checkmark-path { stroke: #292F36 !important; }
  .mat-checkbox-label { font-weight: 400; color: #4D5865; font-size: 14px; }
}

.circle-large-checkbox {
  .mat-checkbox-frame { border-color: #007C89; border-radius: 100%; border-width: 2px; }
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, &.mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: #007C89; border-radius: 100%; }
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element  { background: #007C89; }
  .mat-checkbox-checkmark-path { stroke: #fff !important; }
  .mat-checkbox-inner-container { height: 24px; width: 24px; }
}

// slider
.yellow-slider.mat-slider-horizontal {
  & { padding: 0px; }
  .mat-slider-wrapper, .mat-slider-track-wrapper, .mat-slider-track-background, .mat-slider-ticks-container, .mat-slider-ticks { height: 4px; }
  .mat-slider-track-fill { height: 6px; }
  .mat-slider-track-fill, .mat-slider-thumb, .mat-slider-thumb-label { background-color: #FFDB15; }
  .mat-slider-thumb { width: 30px; height: 30px; bottom: -15px; }
  .mat-slider-track-background { background-color: #FFF7AD; }
  .mat-slider-track-wrapper { border-radius: 4px; }
}

// =================== Global CSS ===================

// =================== Header ===================

.header {
  & { position: fixed; background: #fff; z-index: 99; }
  .header-toolbar {
    & { padding: 0; justify-content: space-between; }
    // logo
    img.logo { margin-top: -10px; }
    // search
    .mat-form-field {
     & {  background: $formbg; width: 320px; border-radius: 5px; }
     .mat-form-field-wrapper { margin: 0; padding: 0; }
     .mat-form-field-infix { padding: 2px 0 14px 0; border-top-width: 10px; }
     .mat-form-field-outline-start, .mat-form-field-outline-end { border-width: 0; }
      input , input::placeholder { font-size: 14px; color: $formtext; font-family: $mulish; }
      .input-search { margin-right: 5px; }
    }
    // header right
    .header-right {
      & { display: flex; align-items: center; flex-wrap: wrap; }
     img { margin: 0px 12px; cursor: pointer; }
    }
  }
  .full-frame { width: calc(100% - 140px); }
}
.header-spacer { padding-top: 64px; }
.public-header {
  & { text-align: right; margin-top: 30px; }
  img { width: 50px; }
}
// =================== Header ===================

// =================== layout ===================

.app-wrapper {
  & { display: flex; flex-wrap: wrap; padding-top: 30px; }
  app-menu { flex: 0 0 300px; margin-right: 15px; }
  router-outlet + * { flex: 1 0 auto; }
}

// ========== menu ==========
.layout-menu {
  & { width: 250px; }
  .user-info {
    & { display: flex; flex-wrap: wrap; align-items: center; border-bottom: 1px solid #C6C6C6; padding-bottom: 15px; }
    img { max-width: 52px; border-radius: 40%; }
    .user-name { margin-left: 10px; font-size: 14px; flex: 1 0 auto; }
    .menu-icon {
      & { display: flex; flex-wrap: wrap; cursor: pointer; }
      span { width: 3px; height: 3px; border-radius: 100%; border: 1px solid #3483FA; margin: 0px 1px; }
    }
  }
  .smiler-menu {
    & { margin-top: 15px; }
    .menu-item {
      & { display: flex; flex-wrap: wrap; align-items: center; margin: 15px 0px; cursor: pointer; }
      .menu-text { margin-left: 15px; color:#656565; font-size: 16px; flex: 1 0 auto; }
      .menu-icon { display: flex; align-items: center; justify-content: center; width: 45px; height: 45px; border-radius: 100%; background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%); box-shadow: 0px 12px 40px rgba(202, 202, 238, 0.4); }
      .menu-notifications { color: #1F4E96; font-size: 12px; background-color: #FFFD00; padding: 2px 8px; border-radius: 10px; font-weight: 700; }
      &.active {
        .menu-icon { background: linear-gradient(90deg, #FFFD00 0%, #FFFFB3 100%); box-shadow: 0px 8px 16px rgba(255, 253, 0, 0.3); }
        .menu-text { font-weight: 600; color: #323232; }
      }
    }
  }
}
// ========== menu ==========

// =================== layout ===================

// =================== login ===================

// =================== login ===================

// =================== register ===================
.smiler-register {
  & { display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 60px; }
  .register-left {
    & { flex: 0 0 45%; margin-top: -60px; }
    .register-wrapper { box-shadow:inset 0px 20px 50px rgba(222, 226 ,230 ,0.25); border-radius: 24px; background-color: #fff; padding: 60px 60px; }
    .form-heading { color: #718194; font-size: 32px; font-weight: 600; line-height: 32px; }
    .form-wrapper {
      & { margin: 70px 0px 0px; }
      form { margin-bottom: 0px; }
    }
    .yellowBtn { margin-top: 30px; }
    .name-wrapper {
      & { display: flex; flex-wrap: wrap; }
      .form-field { flex: 0 0 calc(50% - 5px); width: calc(50% - 5px); }
      .first-name { margin-right: 5px; }
      .last-name { margin-left: 5px; }
    }
  }
  .register-right {
    & { flex: 0 0 55%; text-align: center; }
    .right-title { color: #4D5865; font-size: 20px; font-weight: 300; }
  }
}
// =================== register ===================

// ========================= Smiler CSS ============================== //


.main {
  
  & { max-width: calc(100vw - 300px); transition: .5s; }
  
  .breadcrumb-navlink { cursor: pointer; }
  .statistics {
    
    .stats-container { 
      & { width: 100%; min-height: 170px; display: flex; justify-content: space-between; padding: 10px 0; }
      .stats-box { 
        & { flex: 0 1 19%; display: flex; flex-direction: column; justify-content: space-between; background: #fff; text-align: center; font-size: 14px; }
        h2 { padding-bottom: 0; margin: 0; padding-top: 10px; }
        .count { font-size: 50px; padding-top: 25px; }
        .info { font-size: 12px; padding-bottom: 20px; }
      }
    }
    
  }
  
  .questions {
    .section-name { text-transform: capitalize; }
  }
  
}

.main-closed { width: 92%; max-width: calc(100vw - 90px); flex: none; margin-left: 92px; }


// ========================= Drawers ============================== //

.add-question-drawer {
  .form-control-add-question { & { margin: 0 5px; font-size: 18px; }
  .pageid-pagename-container { width: 90%; display: flex; justify-content: space-between; }
  .page-type-loading-spinner { max-width: 303px; width: 90%; display: flex; align-items: center; border: 1px solid #BEBEBE; padding: 11px 15px; border-radius: 5px; }
    .MuiOutlinedInput-multiline { padding: 10.5px 14px; }
    label { text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #212121; margin-bottom: 8px; }
    .MuiSelect-select { padding: 10px; box-sizing: border-box; height: 40px; }
  }
  .question-mandatory-toggle { 
    & { margin: 0 5px; display: flex; flex-direction: row; gap: 16px; align-items: center; text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #212121; margin-top: 32px; }
    .MuiSwitch-switchBase { top: 7%; left: 5%; }
    .mandatory-switch .Mui-checked { color: #8f51a4; }
  }
  .add-options-and-heading-container { 
    & { display: flex; align-items: center; justify-content: space-between; margin: 24px 0 54px; }
    h3 { align-items: center; text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #212121; }
  }
  .cancel-btn-and-submit-btn {
    & { display: flex; gap: 20px; justify-content: flex-end; }
    .add-question-btn { background-color: #122845; text-align: left; font: normal normal bold 16px/21px $fontLato; letter-spacing: 0px; color: #FFFFFF; width: max-content; padding: 10px 30px; margin-bottom: 100px; border: 2px solid #122845; }
    .cancel-btn { text-align: left; font: normal normal bold 16px/21px $fontLato; letter-spacing: 0px; color: #122845; width: max-content; padding: 10px 30px; margin-bottom: 100px; border: 2px solid #122845; }
    .add-question-btn:hover { color: #122845; background: transparent; }
  }
  .option-btn { 
    & { border: 2px solid #122845; border-radius: 4px; margin: 10px 0; display: flex; align-items: center; text-align: left; font: normal normal bold 16px/21px Lato; letter-spacing: 0px;  }
    .MuiButton-label {
      & { display: flex; align-items: center; gap: 8px; }
      .add-icon {
        & { display: flex; align-items: center; }
        path { fill: #122845; }
      }
    }
    
  }
  .delete-icon { cursor: pointer; margin-top: 30px; }
}
  // .section-select { width: 120px; }
// .section-name { text-transform: capitalize; }

// ========================== Pages ========================== // 

.assessment-name { 
  
  & { background-color: #F4F2EC; font-size: 26px; padding: 15px 30px; margin: 0; display: flex; align-items: center; justify-content: space-between; }
  h1 { margin: 10px auto 0; line-height: 20px; font-family: $fontbutler; letter-spacing: 1px; } 
  .assessment-link-text { font-size: 18px; color: #5B5B5B; margin: 5px auto; }
  .assessment-link { color: #5B5B5B; }
  .header-cta { & { display: flex; align-items: center; justify-content: space-between; cursor: pointer; background-color: #212A42; color: #fff; padding: 7px 10px; border-radius: 3px; font-family: $fontmontserrat; font-weight: 600; font-size: 18px; }
    .MuiSvgIcon-root { margin-right: 3px; }
  }
  .header-cta:hover { background-color: #212A42; color: #fff; }
  .MuiIconButton-root {color: #FFF;  padding: 0px; }
}
.assessment-page, .assessment-dashboard-page { 
  & { padding: 15px 30px; font-family: $fontmontserrat; }
  .tab-container { & { display: flex; flex-direction: row; }
    .box { border-bottom: 1px solid #EDEDED; }
    .total-assessments { position: absolute; right: 4%; text-align: right; width: 28ch; }
    .tab-title { text-transform: capitalize; font-family: $fontmontserrat; font-weight: 600; }
    .search-bar { & { position: relative; width: 250px; }
      input[type="search"].search-field::-webkit-search-cancel-button { -webkit-appearance: none; }
      .search-field { background-color: #FBF9F3; color: #979797; padding: 10px; font-size: 16px; border: 1px solid #CBCBCB; border-radius: 3px; margin: 0 0 15px 0; font-family: $fontmontserrat; width: 250px; }
      .clear-search-icon { position: absolute; right: 8px; bottom: 22px; cursor: pointer; color: #777777; height: 24px; width: 24px; font-size: 24px; }
    }
  }
  .filter-button { 
    &{ position: relative; margin-top: -15px; margin-left: 20px;}
    .MuiButtonBase-root {height: 40px;}
  }
  .delete-user-icon { cursor: pointer; padding: 5px; }
  .search-and-delete { display: flex; justify-content: flex-start; align-items: center; }
  .users-table div.MuiDataGrid-root {
    // min-height: 500px; height: 100%;
    background-color: #FFFFFF; height: 90vh; width: 100%;
    .MuiDataGrid-cell { & { /* text-transform: capitalize; */ }
    .color-coded-blocks { height: 30px; width: 100%; color: #FFFFFF; text-align: center; font-size: 10px; font-weight: 500; display: flex; justify-content: center; align-items: center; border-radius: 5px; cursor: default; }
  }
    [title="Completed"] {
      color: #8fbb69;
    }
    [title="Pending"] {
      color: #d46060;
    }
  }
  // Members Table or Referrals Table 
  .referrals-table {
    div.MuiDataGrid-root { background-color: #FFFFFF; height: 90vh; width: 100%; }
    .MuiDataGrid-cell { /* text-transform: capitalize; */ }
    
  }
  
  .leaders-table {
    div.MuiDataGrid-root { height: 85vh; width: 100%; background-color: #FFFFFF; }
  }
  
  // Statistics Cards in Leaders & Members Tabs 
  .statistics-cards {
    & { display: grid; grid-template-columns: repeat(6, minmax(0, 1fr)); margin: 0 auto 15px 0; max-width: 1150px; overflow-x: auto; }
    .card { & { background: #FFFFFF; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 5px; padding: 5px; margin: 10px 5px; text-align: center; cursor: pointer; border: 1px solid transparent; }
      .big-text { font-size: 36px; font-weight: 700; color: #000000; margin: 10px 10px; }
      .percent-text { font-size: 18px; font-weight: 600; color: #868686; margin: 10px 10px; }
      .info-text { 
        font-size: 14px; font-weight: 600; color: #494949; margin: 10px 5px;
        .delete-icon-container {
          & { margin-top: 20px; float: right; }
          svg { font-size: 16px; margin-top: 0px; color: rgb(175, 175, 175); }
        }
      }
    }
    .card.active { background-color: #F2D466; border: 1px solid #21212177; 
      // box-shadow: 0 0 5px 1px #21212177; 
    }
  }
  
  .dashboard-table {
    div.MuiDataGrid-root { height: 85vh; width: 100%; background-color: #FFFFFF; }
    .statistics-cards {
      & { display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); margin: 0 auto 15px 0; }
      .card { & { background: #FFFFFF; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 5px; padding: 5px; margin: 10px 5px; text-align: center; }
        .big-text { font-size: 36px; font-weight: 700; color: #000000; margin: 10px 10px; }
        .percent-text { font-size: 18px; font-weight: 600; color: #868686; margin: 10px 10px; }
        .info-text { font-size: 14px; font-weight: 600; color: #494949; margin: 10px 10px; }
      }
    }
  }

  .statistics-dash {
    .MuiBox-root { padding: 0; }
  }

  .MuiDataGrid-cell { font-family: $fontmontserrat; font-size: 14px; }
  .MuiDataGrid-main {
    .MuiDataGrid-columnHeaderTitle {
      font-weight: 700; font-family: $fontmontserrat; font-size: 15px;
    }
    .MuiDataGrid-sortIcon { opacity: 1 !important; }
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaders { background-color: #F1F1F1; color: #494949; }
  .user-info-link { cursor: pointer; color: #212A42; font-family: $fontmontserrat; font-weight: 600; font-size: 14px; /* text-transform: capitalize; */ text-decoration: underline; }
  .user-info-link.member { font-weight: 400; }
}

.engagement { & { padding: 15px 30px; font-family: $fontmontserrat; }
  h1 { margin: 10px auto 5px; }
  .engagement-link { display: block; margin: 0 auto 10px; font-size: 18px; color: #5B5B5B; }
  .tab-container { & { display: flex; flex-direction: row; }
    .box { border-bottom: 1px solid #EDEDED; }
    .tab-title { text-transform: capitalize; font-family: $fontmontserrat; font-weight: 500; color: #343333; }
    .tab-title.Mui-selected { font-weight: 600; color: #1F2A44; }
  }
  .grid {
    .MuiBox-root { padding: 0; }
  }
  

  .subscription-grid-container { & { min-height: 80vh; font-family: $fontmontserrat; font-size: 16px; max-width: 100%; margin: 15px auto; }
    // .MuiDataGrid-root {  }
    .MuiDataGrid-main .MuiDataGrid-columnHeaderTitle { font-weight: 700; font-family: "Montserrat", sans-serif; font-size: 15px; }
  }
  .subscribers-table-container { & { background-color: #FBF9F3; margin-top: 30px; }
    .subscribers-table { & { width: 95%; max-width: 100%; margin: 0 auto; background-color: #ffffff; font-family: $fontbutler; }
    th, td { font-size: 18px; font-family: $fontmontserrat; }
  }
}
  .red { background-color: #DC4A37; }
  .orange { background-color: #e67e22; }
  .green { background-color: #73B76F; }
  .chip { width: 15ch; color: #ffffff; }
  .chip .MuiChip-label { font-size: 14px; color: #ffffff; }
}


main .see-more-btn, main .bulk-delete-btn { background-color: #212A42; color: #ffffff; font-weight: 500; font-size: 14px; letter-spacing: 1px; padding: 6px 12px; }
main .see-more-btn:hover { background-color: #B9B9B9; border: 1px solid #343333; }
main .bulk-delete-btn:hover { background-color: #212A42; color: #ffffff; box-shadow: 1px 1px 5px 1px #7f7f7f; }
main .see-more-btn { font-family: $fontmontserrat; background-color: #D9D9D9; color: #1F2A44; font-weight: 600; letter-spacing: 0; }
main .bulk-delete-btn { padding: 8px 12px; margin: 0 10px 15px; }
main .call-btn.blue { color: cornflowerblue; color: #999999; }
main .call-btn.done { color: #59945A; color: #999999; }
main .call-btn.hide { display: none; }
// main .call-btn.hide { color: crimson; }
// main .call-btn { cursor: pointer; }

// Universal Styles 
.loader-section { display: flex; min-height: 300px; justify-content: center; align-items: center; }
ul.unstyled { list-style: none; padding: 0; }
ul.unstyled li { & { padding: 5px 0; text-align: right; }
  input { padding: 5px; }
}
.ql-toolbar { position: absolute; top: -115px; background-color: #212121; width: 330px; margin: 0 auto; left: -165px; }
.editor .ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow { border-bottom: 6px solid #212121; top: -75px; transform: rotate(180deg); }
.tox.tox-tinymce.tox-tinymce-inline { max-width: 480px; }


// =========== Assessment Builder Page =============== 



.input-field-heading { & { display: flex; justify-content: space-between; } 
   .field-heading { font-size: 26px; color: #212121; font-family: $fontmontserrat; }   
}
.field-list { & {}
  .field-select-type { & { display: flex; justify-content: space-between; } 
  .MuiInput-underline::before { border-bottom: none; content: ""; transition: none; }
  .MuiInput-underline::after { border-bottom: none; content: "";  transition: none; }
  .MuiInput-underline:hover:not(.Mui-disabled)::before { border-bottom: none; }
  .MuiInput-underline:hover:not(.Mui-disabled)::after { border-bottom: none; }

  .MuiSelect-select  { padding-left: 12px; color: #000000; font-size: 18px; font-family: $fontmontserrat; } 
  
}
  .input-labels {& { margin-top: 15px; display: flex; justify-content: space-between; }
    .MuiFormControl-root { border: 1px solid #BEBEBE; border-radius: 8px; width: 270px; }
    .MuiInput-input { padding-left: 12px; font-size: 18px; font-family: $fontmontserrat; }
  }

  .dropdown {& {display: flex; flex-wrap: wrap; justify-content: center; border-radius: 8px; margin-top: 25px; }
    .dropdown-item { border: 1px solid #BEBEBE; max-width: 279px; display: flex; height: 52px; }
    .dropdown-item-name-active {border: 1px solid #BEBEBE; height: 32px; border-radius: 8px; margin: 10px; justify-content: center; }
    .MuiInput-input { padding-left: 10px; font-size: 18px; font-family: $fontmontserrat; margin-top: 6px; }
    .Mui-disabled { color: #000; margin-top: 5px; }
    .edit-save-button { background-color: #122845; border-radius: 8px; color: #ffffff; height: 32px; margin: 10px 10px 0px 0px; }
  }

  .members-button {& { display: flex; margin-top: 15px; }
    .MuiFormControlLabel { }
}
}

.invite { & {}
  .MuiInputBase-input{ padding-top: 8px; padding-left: 12px; font-family: Barlow, Nunito, san-serif, Lato; }

}


 //DELETE MODAL//
//  .delete-container { & { }
 .MuiBackdrop-root { background-color: rgba(0, 0, 0, 0.19); }
 .MuiDialog-container { & {}
   .MuiDialog-paperWidthSm { width: 600px; }
   .MuiButton-text {color: #000000; }
}

// }

// COLUMN_MODAL //

.column-data-modal { & { } 

  .column-header { & { display: flex; justify-content: space-between; align-items: center; }
    h2 { font-size: 30px; color: #212121; }
}
  .search-container { & { display: flex; justify-content: space-between; align-items: center; }
    .MuiInput-root { border: 1px solid #BEBEBE; border-radius: 4px; height: 35px; }
    .MuiInput-input { padding-inline-start: 5px; }
    .MuiInputBase-fullWidth { width: 100%; }
    .MuiInput-underline::after { border-bottom: none; transition: none;  content: none; }
    .MuiInput-underline::before { border-bottom: none; transition: none;  content: none; }
    .MuiButton-root { color: #ffffff; background-color:  #122845; border-radius: 4px; height: 35px; width: 200px; }
  }

  .modal-fields { & { margin-top: 10px; border-radius: 4px; border: 1px solid #BEBEBE;}
    .MuiFormControl-root { width:  100%; } 
    .MuiFormControlLabel-root { display: flex; flex-direction: row-reverse; justify-content: space-between; border-bottom: 1px solid #E7EAEC; padding-left: 5px; height: 35px; margin-right: 0px; margin-left: 0px; padding-right: 10px; }
    .MuiTypography-root { color: #000000; font-size: 18px; font-family: $fontmontserrat; margin-left: 5px; }
  }
  .modal-btns {& { display:  flex; justify-content: space-around;}
    .save-btn { margin-top: 10px; width: 250px; border-radius: 4px; background-color: #122845; color: #ffffff;  } 
    .cancel-btn { margin-top: 10px; width: 250px; border-radius: 4px; background-color: #00000014; color: #1a1919; }
    .save-btn-disabled { margin-top: 10px; width: 250px; border-radius: 4px; background-color: #00000014; color: #ffffff; }
}
}

.column-modal { top: 0; left: 0; right: 0; bottom: 0; z-index: -1; position: fixed; align-items: center;  justify-content: center; background-color: rgba(0, 0, 0, 0.5); -webkit-tap-highlight-color: transparent; }

.assessment-page {
  & {  }
  .page-header {
    & { display: flex; align-items: center; justify-content: space-between; }
    h1 { font-weight: 600; }
    .create-btn { background-color: #212A42; color: #ffffff; padding: 8px 16px; font-size: 16px; font-family: $fontmontserrat; }
  }
}


.assessment-page-container {
     .page-header {
       & { display: flex; align-items: center; justify-content: space-between; margin: 20px; }
      h1 { 
        &{font-weight: 600;  font-size: 24px; font-weight: bold;  letter-spacing: 0px;color: #57606F;}
      }
      .create-btn { 
        &{background-color: #212A42; color: #ffffff; padding: 8px; font-size: 16px; font-family: $fontmontserrat;width: 107px;height: 40px; border-radius: 4px; font-weight: bold; }
      }
      
     }
      .MuiDataGrid-root .MuiDataGrid-columnHeaders{ background-color: #F1F1F1; color: #5B5B5B;  font-size: 15px;}
     .assesment-name{
        &{display: flex; flex-direction: column;}
        div{
          &{color: #0F131D;font-weight: 500;font-size: 14px;font-family: "Montserrat", sans-serif;}
          }
          .editdetails{ display: flex; align-items: center; gap: 5px; }
        }
        .userinfo{ color: #0F131D; font-size: 14px; }
        .current-status{
          & { display: flex; justify-content: center; align-items: center; width: 80px; height: 32px; border-radius: 50px; }
          p{ font-size: 14px; }
        }
        .online{
          & { background-color: #CEF1CF; }
          p{ color: #4D9950; }
        }
        .offline{
          & { background-color: #FAD3D3; }
          p{ color: #E14949; }
        }
        .pause{
          & { background-color: #FBF2D1; }
          p{ color: #F2D466; }
        }
        .view-btn{ margin-top: 12px; margin-bottom: 12px; width: 67px;height: 32px;background-color: #122845; color: #fff; border-radius: 4px; font-size: 14px; cursor: pointer;display: flex; justify-content: center; align-items: center;}
        img{cursor: pointer; height: 20px;}
        .MuiDataGrid-iconSeparator { display: none;}
        .css-1kwdphh-MuiDataGrid-virtualScrollerContent{background-color: #ffffff;}
        .img-container{
         &{ display: flex; flex-direction: column; margin: 12px;gap: 2px;}
         div{color: #0F131D;font-weight: 400;font-size: 10px;font-family: "Montserrat", sans-serif;}
        }
          .assesment-head{width: 20% !important;}
          .assesment-date{ white-space: nowrap;}
        .MuiTableHead-root{background-color: #F1F1F1;  cursor: pointer;}
        .MuiTableContainer-root {width: 80vw;margin: 15px; height: 80vh; overflow: scroll;}
        .MuiTableCell-head {width: 10%;}
        .edit-row{visibility: hidden;display:flex;justify-content: end;align-items: center }
        .edit-container{display:flex;justify-content: end;align-items: center}
        .MuiTableRow-root:hover .edit-row{display: flex;justify-content: center;align-items: center;cursor: pointer; height: 61px; visibility: visible; }
        .MuiTableRow-root:hover {background-color: #F1F1F1; height: 31px;}
        .MuiTableCell-body { padding-top: 0px; padding-bottom: 0px; }
          .search-bar { & { position: relative; width: 250px; left: 13px; }
          input[type="search"].search-field::-webkit-search-cancel-button { -webkit-appearance: none; }
          .search-field { background-color: #FBF9F3; color: #000000; padding: 10px; font-size: 16px; border: 1px solid #CBCBCB; border-radius: 3px; margin: 0 0 15px 0; font-family: $fontmontserrat; width: 250px; }
          .clear-search-icon { position: absolute; right: 8px; bottom: 23px; cursor: pointer; color: #777777; height: 24px; width: 24px; font-size: 24px; }
        }
        .no-data-found{ display: flex; justify-content: center; align-items: center; height: 100%;}
      .arrow-updown{visibility: hidden;}
       .assesment-head:hover .arrow-updown-head{visibility: visible;}
      .assesment-leader,.assesment-member ,.assesment-user{width: 20%;}
     
}

.api-page-container {
  & { margin: 12px; }
  .status-setting {
    & {position: relative;}
  .setting {
      & {display: flex; justify-content: end; align-items: center; gap: 24px; margin-bottom: 12px;} 
      .current-status{
          & { display: flex; justify-content: center; align-items: center; width: 80px; height: 32px; border-radius: 50px; }
          p{ font-size: 14px; }
        }
        .online{
          & { background-color: #CEF1CF; }
          p{ color: #4D9950; }
        }
        .offline{
          & { background-color: #FAD3D3; }
          p{ color: #E14949; }
        }
        .pause{
          & { background-color: #FBF2D1; }
          p{ color: #F2D466; }
        }
    }
    .dropdown {
      & {position: absolute; z-index: 1; right: 0; padding: 4px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); border-radius: 4px;}
      .notifications { cursor: pointer; display: flex; align-items: center; font-size: 14px; size: 14px;}
    }
  }
  .cpu-container {
    & { width: 98%; height: 500px; padding: 12px; border-radius: 4px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }

    p { font-size: 24px; }
  }
}

.logs-page-container {
  & { margin: 12px; }
  .page-body {
    .server-types {  
      &{ display: flex; gap: 36px; } 
      p{ padding-top: 0; padding-left: 12px; padding-right: 12px; cursor: pointer; }
      .underline { height: 3px; background-color: $themecolor2; }
      .active { font-weight: 700; color: $themecolor2; }
    }
    .logs{ width: 98%; height: 600px; border: 2px solid #3443; border-radius: 4px; margin-top: 12px; }
  }
}

.notification-page-container {
    & { margin: 12px; }
  .card {
    & { background-color: #fff; margin: auto; margin-top: 48px; width: 80%; height: 600px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); border-radius: 8px; }
    .content {
      & { padding: 12px; }
      h2 { color: #212121; }
      .notification-dropdown {
         .Mui-checked { color: $themecolor2; }
      }
      .members-list {
        & { height: 250px; overflow-y: auto; margin-top: 12px; }
        .Mui-checked { color: $themecolor2; }
      }
      .buttons{
        & {display: flex; justify-content: end; gap: 12px; margin-top: 24px; }
        .cancel-btn { cursor: pointer; border: 2px solid $themecolor2; border-radius: 4px; padding: 4px; padding-left: 12px; padding-right: 12px; color: $themecolor2; font-weight: 700; text-align: center;}
        .notify-btn { cursor: pointer; padding: 6px; padding-left: 12px; padding-right: 12px; background-color: $themecolor2; color: #fff; font-weight: 600; border-radius: 4px; text-align: center;}
      }
    }
  }
}

 .main .MuiTableCell-head {text-align: left; }
.MuiTab-textColorInherit.Mui-selected {font-weight: bold !important; font-size: 16px;}
.css-r11z79-MuiDataGrid-root .MuiDataGrid-cell--textCenter { justify-content: left !important;}
